import './scss/main.scss';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

// $('.btn-loader')
// 	.not('[data-init]')
// 	.attr('data-init', true)
// 	.on('click', e =>
// 		$(e.currentTarget)
// 			.attr('disabled', true)
// 			.append('<i class="fa fa-spin fa-spinner"></i>')
// 	);

Sys.Application.add_load(function() {
	$('[data-on-enter]')
	.not('[data-on-enter-init]')
	.attr('data-on-enter-init', true)
	.on('keypress', 'input[type=text]', e => {
		const $this = $(e.delegateTarget);
	
		if (e.which == 13 || e.keyCode == 13) {
			$this
				.closest('[data-parent]')
				.find('.UpdateButton')
				.trigger('click');
	
			return false;
		}
	
	});
	
	$('.flat-pagination')
	.not('[data-pagination-init]')
	.attr('data-pagination-init', true)
	.on('click', 'a', function(e) {
		const top = $('#breadcrumbs').offset().top;
	
		$('html, body').animate({ scrollTop: top }, 300);
	})
});
